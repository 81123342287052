.desktopNavAll {
  position: relative;
}

.navbar {
  background: transparent;
  padding: 16px 0px;
}

/* Added to make the navbar align properly on desktop. */
#responsive-navbar-nav {
  width: fit-content;
}

.nav-link,
MyHashLink {
  font-size: 14px;
  padding: 16px 15px !important;
  color: #fff !important;
  font-weight: 600;
  text-transform: uppercase;
}

.nav-link:hover {
  color: rgba(255, 255, 255, 0.85) !important;
}

.navbar a {
  color: #fff;
}

.dropdown-menu {
  padding: 15px 15px;
  margin-top: 0;
  width: auto;
  font-size: 14px;
  text-decoration: none;
  font-weight: 500;
  border-bottom-left-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
  border-top-left-radius: 0px !important;
  border-top-right-radius: 0px !important;
  border: none;
  background: rgba(245, 145, 30, 0.95);
  box-shadow: 1px 1px 8px #f5911e;
}

.dropdown-item:focus,
.dropdown-item:hover {
  background-color: transparent;
}

.dropdown-item {
  color: #fff !important;
}

.dropdown-item:hover {
  color: rgba(255, 255, 255, 0.8) !important;
  background-color: transparent;
}

.scrolled {
  background-color: #f5911e !important;
}

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1);
}
.navbar-toggler {
  color: rgba(255, 255, 255) !important;
  border: none;
}

#responsive-navbar-nav .navbar-nav {
  padding-left: 10px;
}
.homeIcon {
  font-size: 18px;
  padding-bottom: 3px;
}

@media (max-width: 500px) {
  .applyModalWrapper .form-control {
    font-size: 14px;
  }
}

@media screen and (max-width: 768.99px) {
  .navbar {
    padding: 6px;
  }
  .dropdown-menu {
    width: 95%;
  }
}

@media (max-width: 991.99px) {
  .navbar {
    background: #f5911e !important;
    padding: 16px 0px;
  }
  .nav-link,
  MyHashLink {
    font-size: 13px;
  }
}

@media screen and (max-width: 1299px) {
  .nav-link,
  MyHashLink {
    padding: 16px 10px !important;
  }
}

@media (hover: hover) and (pointer: fine) {
  .dropdown:hover .dropdown-menu {
    display: block;
  }
}
