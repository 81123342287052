/*banner-info-business*/
section.nexa-banner-business {
  position: relative;
}

.banner-img {
  position: relative;
  background-color: #0054a6;
  background-size: cover;
  overflow: auto;
}

section.nexa-banner-business .banner-img > img {
  object-fit: cover;
  position: relative;
  height: 630px;
}

section.nexa-banner-business .banner-shape {
  position: absolute;
  bottom: -7%;
  width: 100%;
}

section.nexa-banner-business .banner-shape img {
  width: 100%;
}

section.nexa-banner-business .overlay-banner {
  background-color: #605e5b;
  left: 0;
  opacity: 0.4;
  height: 100%;
  position: absolute;
  top: 0;
  width: 100%;
}

section.nexa-banner-business .banner-inner-info {
  position: absolute;
  top: 29%;
  width: 80%;
}

section.nexa-banner-business .banner-title h4 {
  color: #fff;
  font-size: 40px;
  line-height: 1.3;
  padding-bottom: 13px;
  font-weight: 300;
  width: 41%;
}

section.nexa-banner-business .banner-title h2 {
  color: #d1d1d1;
  font-size: 18px;
  line-height: 1.6;
  padding-bottom: 53px;
  width: 55%;
  opacity: 1;
  font-weight: lighter;
}

section.nexa-banner-business .banner-inner-info .btn-video {
  text-transform: uppercase;
}

section.nexa-banner-business .banner-title .btn.btn-success {
  background-color: #f5911e;
  border: 1px solid #f5911e;
  border-radius: 28px;
  color: #fff;
  float: left;
  font-weight: bold;
  padding: 16px 40px;
  margin-right: 45px;
}

section.nexa-banner-business .banner-title .btn.btn-success:hover {
  background-color: #fff;
  border: 1px solid #f5911e;
  color: #f5911e;
  -webkit-transition-duration: 0.6s;
  /* Safari */
  transition-duration: 0.6s;
}

section.nexa-banner-business .banner-title .fa {
  background-color: rgba(0, 0, 0, 0);
  border: 2px solid rgb(144, 149, 147);
  border-radius: 34px;
  color: rgb(255, 255, 255);
  font-size: 16px;
  height: 40px;
  margin-right: 11px;
  padding-left: 4px;
  padding-top: 11px;
  text-align: center;
  width: 40px;
}

section.nexa-banner-business .banner-title h3 {
  color: #fff;
  display: inline-block;
  font-size: 20px;
  margin-left: 7px;
  margin-top: 4px;
  font-weight: 500;
  font-size: 14px;
}

section.nexa-banner-business .banner-title .fa {
  color: #fff;
  font-size: 16px;
  width: 40px;
  height: 40px;
  background-color: transparent;
  text-align: center;
  padding-top: 11px;
  border: 3px solid #d1d1d1;
  border-radius: 34px;
  padding-left: 4px;
  margin-right: 11px;
  text-align: center;
}

section.nexa-banner-business .banner-title span.video-title {
  color: #fff;
  display: inline-block;
  margin-left: 7px;
  margin-top: 12px;
}

section.nexa-banner-business .video-btn {
  float: left;
  padding-top: 7px;
}

section.nexa-banner-business .video-btn a:hover i.fa {
  border-color: rgb(79, 190, 110);
  color: rgb(79, 190, 110);
}

section.nexa-banner-business .video-btn .fa.fa-play-circle.fa-4x:hover {
  color: #4fbe6e;
}

section.nexa-banner-business .carousel-indicators li {
  background-color: #515557;
  border: 1px solid #515557;
  border-radius: 10px;
  cursor: pointer;
  display: inline-block;
  height: 6px;
  margin: 1px 14px 1px 1px;
  text-indent: -999px;
  width: 30px;
}

section.nexa-banner-business .carousel-indicators .active {
  background-color: #fff !important;
  height: 6px;
  border-color: #fff;
  margin: 0;
  width: 30px;
  margin: 1px 14px 1px 1px;
}

section.nexa-banner-business .carousel-indicators {
  bottom: 105px;
}

@media screen and (min-width: 992px) {
  section.nexa-banner-business {
    margin-top: 85px;
  }
}

@media screen and (max-width: 991.98px) {
  section.nexa-banner-business {
    margin-top: 165px;
  }
}

@media screen and (max-width: 767.99px) {
  section.nexa-banner-business {
    margin-top: 75px;
  }
  section.nexa-banner-business .banner-inner-info {
    display: none;
  }

  section.nexa-banner-business .banner-shape {
    display: none;
  }
  section.nexa-banner-business .banner-inner-info2 .btn-success {
    position: absolute;
    top: 50%;
    width: 40% !important;
    padding: 20px 30px !important;
  }

  section.nexa-banner-business .carousel {
    clip-path: unset;
  }

  section.nexa-banner-business .banner-img > img {
    height: 200px;
    object-fit: cover;
    clip-path: unset;
    border-radius: 0;
  }

  section.nexa-banner-business .carousel-indicators li {
    height: 5px;
    margin: 0 7px 1px 1px;
    width: 27px;
  }

  section.nexa-banner-business .carousel-indicators .active {
    height: 5px;
    margin: 1px;
    width: 27px;
  }

  section.nexa-banner-business .carousel-inner {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    clip-path: unset;
    width: 100%;
    height: 100%;
  }

  section.nexa-banner-business .carousel-indicators {
    bottom: 24%;
    z-index: 15;
  }

  section.nexa-banner-business .overlay-banner {
    height: 99%;
  }
}

@media screen and (max-width: 991px) and (min-width: 767px) {
  section.nexa-banner-business .banner-shape {
    display: none;
  }

  section.nexa-banner-business .banner-inner-info {
    top: 50%;
  }

  section.nexa-banner-business .carousel {
    clip-path: unset;
  }

  section.nexa-banner-business .banner-title h4 {
    font-size: 29px;
    line-height: 1.2;
    padding-bottom: 15px;
    width: 100%;
  }

  section.nexa-banner-business .carousel-inner {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    clip-path: unset;
    width: auto;
    height: 100%;
  }

  section.nexa-banner-business .banner-title h2 {
    color: #d3d3d3;
    font-size: 15px;
    padding-bottom: 15px;
    width: 100%;
  }

  section.nexa-banner-business .banner-title .btn.btn-success {
    padding: 10px 27px;
  }

  section.nexa-banner-business .banner-title h3 {
    margin-top: 9px;
  }

  section.nexa-banner-business .video-btn {
    padding-top: 3px;
  }

  section.nexa-banner-business .banner-img > img {
    height: 370px;
    clip-path: unset;
    border-radius: 0;
  }

  section.nexa-banner-business .carousel-indicators {
    bottom: 18px;
  }

  section.nexa-banner-business .overlay-banner {
    height: 99%;
  }
}

@media screen and (max-width: 1191px) and (min-width: 991px) {
  section.nexa-banner-business .banner-title h4 {
    width: 100%;
  }

  section.nexa-banner-business .banner-shape {
    display: none;
  }

  section.nexa-banner-business .carousel-inner {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    clip-path: unset;
  }

  section.nexa-banner-business .carousel {
    clip-path: unset;
  }

  section.nexa-banner-business .banner-img > img {
    height: 550px;
  }

  section.nexa-banner-business .banner-inner-info {
    top: 35%;
  }

  section.nexa-banner-business .overlay-banner {
    height: 99.5%;
  }

  section.nexa-banner-business .carousel-indicators {
    bottom: 55px;
  }
}

@media screen and (max-width: 1550px) and (min-width: 1500px) {
  section.nexa-banner-business .banner-title h4 {
    width: 36%;
  }

  section.nexa-banner-business .banner-title h2 {
    width: 50%;
  }
}

@media screen and (max-width: 1750px) and (min-width: 1650px) {
  section.nexa-banner-business .banner-img > img {
    height: 815px;
  }

  section.nexa-banner-business .banner-inner-info {
    top: 34%;
  }

  section.nexa-banner-business .banner-title h4 {
    width: 32%;
  }

  section.nexa-banner-business .banner-title h2 {
    width: 44%;
  }

  section.nexa-banner-business .carousel-indicators {
    bottom:
            /*50px*/ 127px;
  }

  section.nexa-banner-business .carousel-indicators li {
    margin: 1px 14px 1px 1px;
  }

  section.nexa-banner-business .carousel-indicators .active {
    margin: 1px 14px 1px 1px;
  }
}

@media screen and (max-width: 2300px) and (min-width: 2001px) {
  section.nexa-banner-business .banner-inner-info {
    top: 30%;
    width: 45%;
  }

  section.nexa-banner-business .banner-title .fa {
    padding-top: 11px;
  }

  section.nexa-banner-business .banner-title h2 {
    width: 60%;
  }

  section.nexa-banner-business .banner-title h4 {
    width: 50%;
    padding-right: 15px;
  }
}

@media screen and (max-width: 2050px) and (min-width: 1751px) {
  section.nexa-banner-business .banner-img > img {
    height: 830px;
  }

  section.nexa-banner-business .carousel-indicators {
    bottom: 168px;
  }
}

@media screen and (max-width: 2030px) and (min-width: 1800px) {
  section.nexa-banner-business .banner-inner-info {
    width: 50%;
  }

  section.nexa-banner-business .banner-title h4 {
    width: 49%;
  }

  section.nexa-banner-business .banner-title h2 {
    width: 65%;
  }

  section.nexa-banner-business .banner-title .fa {
    padding-top: 11px;
  }
}

@media screen and (max-width: 2790px) and (min-width: 2400px) {
  section.nexa-banner-business .banner-title .fa {
    padding-top: 10px;
  }

  section.nexa-banner-business .banner-inner-info {
    top: 29%;
    width: 37%;
  }

  section.nexa-banner-business .banner-title h4 {
    width: 46%;
  }

  section.nexa-banner-business .banner-title h2 {
    width: 55%;
  }

  section.nexa-banner-business .carousel-indicators {
    bottom: 180px;
  }

  section.nexa-banner-business .banner-img > img {
    height: 840px;
  }
}
section.nexa-banner-business .video-btn {
  float: left;
  padding-top: 7px;
}

section.nexa-banner-business .video-btn a {
  text-decoration: none;
  color: #fff;
}

section.nexa-banner-business .video-btn a:hover {
  color: #4fbe6e;
}

section.nexa-banner-business .video-btn a:hover .fa {
  color: #4fbe6e;
  border-color: #4fbe6e;
}

#messageBox {
  border: none;
  padding-bottom: 30px;
}

#messageBox p {
  text-align: center;
  font-weight: bold;
}

#messageBox p button {
  border: none;
  padding: 0;
  margin: 0;
  color: #0054a6;
  font-family: inherit !important;
  font-weight: inherit !important;
  font-size: inherit !important;
  background-color: inherit !important;
  transition: all .5s ease;
}

#messageBox p button:hover {
  color: #15549a;
}

.portfolio-details button {
  border: none;
  padding: 0;
  margin: auto !important;
  width: 100%;
  background-color: transparent;
  color: #fff;
}

.portfolio-details button h2 {
  padding: 14px 0 10px 0;
  color: inherit;
}

#messageBox a {
  color: #0054a6;
}

#messageBox span {
  color: #f5911e;
}

section.nexa-portfolio-business {
  border-bottom: 1px solid #f7f7f7;
  padding-bottom: 132px;
  padding-top: 59px;
}

section.nexa-portfolio-business .portfolio-title > h3 {
  color: #222;
  font-size: 31px;
  text-align: center;
  font-weight: lighter;
  line-height: 2.2;
}

section.nexa-portfolio-business .portfolio-title > h2 {
  font-size: 18px;
  text-align: center;
  padding-bottom: 68px;
  font-weight: lighter;
  color: #b2b2b2;
}

section.nexa-portfolio-business
  .portfolio-details
  .img-responsive.portfolio-img {
  height: 221px;
  object-fit: contain;
  width: 100%;
}

section.nexa-portfolio-business .portfolio-details {
  background-color: #f5911e;
  box-shadow: 0 0 10px #e8e8e8;
  margin-bottom: 20px;
  border: 1px solid #f5911e;
  border-radius: 8px;
}

section.nexa-portfolio-business .portfolio-details > a > h2 {
  color: white;
  font-size: 19px;
  line-height: 2;
  text-align: center;
  padding-top: 14px;
}

section.nexa-portfolio-business .portfolio-details > a {
  text-decoration: none;
}

section.nexa-portfolio-business .portfolio-details > h2 {
  color: #bababa;
  padding-left: 29px;
  font-size: 16px;
}

section.nexa-portfolio-business .portfolio-details:hover {
  background-color: #fff;
  border: 1px solid #f5911e;
  color: #f5911e !important;
  -webkit-transition-duration: 0.6s;
  transition-duration: 0.6s;
}

section.nexa-portfolio-business .portfolio-details:hover h1,
.portfolio-details:hover h2 {
  color: #f5911e !important ;
}

section.nexa-portfolio-business .carousel-indicators li {
  background-color: #ececec;
  border: 1px solid #ececec;
  border-radius: 10px;
  cursor: pointer;
  margin: 1px 1px 1px 10px;
  display: inline-block;
  height: 5px;
  margin: 1px;
  text-indent: -999px;
  width: 29px;
}

section.nexa-portfolio-business .carousel-indicators .active {
  background-color: #d0d0d0;
  height: 7px;
  margin: 0;
  width: 29px;
}

section.nexa-portfolio-business .carousel-indicators {
  bottom: -71px;
}

@media screen and (max-width: 767px) {
  section.nexa-portfolio-business {
    padding-top: 40px !important;
    padding-bottom: 40px;
  }

  section.nexa-portfolio-business .img-responsive.portfolio-img {
    width: 100%;
  }

  section.nexa-portfolio-business .carousel-indicators {
    bottom: -25px;
  }

  section.nexa-portfolio-business .portfolio-details {
    margin-bottom: 40px;
  }
}

@media screen and (max-width: 991px) and (min-width: 767px) {
  section.nexa-portfolio-business {
    padding-bottom: 65px;
    padding-top: 45px;
  }

  section.nexa-portfolio-business .portfolio-details > a > h2 {
    line-height: unset;
    padding-bottom: 18px;
    font-size: 16px;
  }

  section.nexa-portfolio-business .portfolio-details {
    margin-bottom: 40px;
  }

  section.nexa-portfolio-business .carousel-indicators {
    bottom: -30px;
  }
}

@media screen and (max-width: 1198px) and (min-width: 991px) {
  section.nexa-portfolio-business
    .portfolio-details
    .img-responsive.portfolio-img {
    height: 100%;
  }
}

section.nexa-video-business .about-video-shapes {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.nexa-video-business .about-video-shapes img {
  width: 100%;
  height: 605px;
}

section.nexa-video-business {
  margin-top: 98px;
  padding-bottom: 84px;
  position: relative;
}

section.nexa-video-business .about-video-img .about-img-video {
  border-radius: 0 24% 28% 0;
  height: 605px;
  object-fit: cover;
  object-position: left center;
  position: absolute;
  width: 522px;
  clip-path: polygon(0px 100%, 0 0px, 100% 0px, 100% 100%);
}

section.nexa-video-business .video-about-detail {
  padding-top: 80px;
}

section.nexa-video-business .about-play-img .fa {
  float: right;
  margin-right: 11%;
  margin-top: 78%;
  width: 106px;
  height: 106px;
  background-color: #0054a6;
  border-radius: 64px;
  color: #fff;
  font-size: 46px;
  text-align: center;
  padding-top: 18px;
  padding-left: 7px;
}

section.nexa-video-business .about-play-img .fa:hover {
  background-color: #0053a6ee;
  box-shadow: 2px 2px 5px #d2d2d2;
}

section.nexa-video-business .video-about-detail > h5 {
  font-size: 31px;
  font-weight: lighter;
  line-height: 1.5;
  padding-bottom: 39px;
  /*    width: 50%;*/
}

section.nexa-video-business .video-about-detail > p {
  color: #8a8a8a;
  font-size: 18px;
  padding-bottom: 40px;
  line-height: 1.7;
}

section.nexa-video-business .video-about-detail > h2 {
  color: #8a8a8a;
  font-size: 15px;
  padding-bottom: 42px;
}

section.nexa-video-business .about-list > li {
  display: inline-block;
  padding-right: 85px;
}

section.nexa-video-business .about-list h3 {
  border-bottom: 2px solid #cecece;
  font-size: 31px;
  text-align: center;
  padding-bottom: 11px;
  color: #0050be;
  width: 80%;
}

section.nexa-video-business .about-list h4 {
  color: #676767;
  font-size: 18px;
  padding-top: 11px;
  font-weight: lighter;
}

section.nexa-video-business .free-quote h3 {
  color: #fff;
  font-size: 27px;
  line-height: 2;
}

section.nexa-video-business .free-quote h3:hover {
  color: #e8e8e8;
  font-size: 27px;
  line-height: 2;
}

section.nexa-video-business .free-quote > a {
  text-decoration: none;
}

section.nexa-video-business .free-quote h2 {
  color: #96d5f1;
  font-size: 18px;
}

body.modal-open section.nexa-video-business {
  position: unset;
}

section.nexa-video-business .modal-dialog {
  margin: 125px auto;
}

section.nexa-video-business .modal-body {
  padding: 0px;
  background-color: #8a8a8a;
}

section.nexa-video-business video {
  width: 598px;
}

section.nexa-video-business button.close {
  background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
  border: medium none;
  cursor: pointer;
  padding: 0 5px 1px 6px;
}

section.nexa-video-business .close {
  border: 1px solid #000;
  color: #000;
  float: right;
  font-size: 21px;
  font-weight: 700;
  line-height: 1;
  opacity: 0.6;
  position: relative;
  right: 0;
  text-shadow: 0 1px 0 #fff;
  top: 0;
}

.about-play-img > h4 {
  display: none;
}

.Typewriter {
  height: 40px;
}

@media screen and (max-width: 391.99px) {
  section.nexa-banner-business .banner-inner-info2 .btn-success {
    font-size: 12px;
    top: 50%;
    width: 35% !important;
    padding: 10px !important;
  }
  .Typewriter {
    height: 50px;
  }
  section.nexa-portfolio-business {
    padding-top: 20px !important;
  }
}

@media screen and (max-width: 591.99px) {
  #home {
    margin-top: 65px;
  }
}

@media screen and (max-width: 767px) {
  section.nexa-video-business {
    margin-top: 0px;
  }

  section.nexa-video-business .about-video-img {
    display: none;
  }

  section.nexa-service-business {
    padding-bottom: 30px;
    padding-top: 50px;
  }

  .about-play-img > h4 {
    font-size: 24px;
    margin-left: 91px;
    display: block;
    width: 67%;
    float: left;
    margin-top: -45px;
  }

  section.nexa-video-business .video-about-detail {
    padding-top: 40px;
  }

  section.nexa-video-business .about-play-img .fa {
    font-size: 32px;
    float: left;
    margin-top: 0;
    width: 68px;
    height: 68px;
    margin-right: 0;
    padding-top: 10px;
  }

  section.nexa-video-business video {
    width: 299px;
  }

  section.nexa-video-business .modal-dialog {
    margin: 200px 10px 10px;
    position: relative;
    width: auto;
  }

  section.nexa-video-business .video-about-detail > h5 {
    padding-bottom: 25px;
    width: 100%;
  }

  section.nexa-video-business .video-about-detail > p {
    font-size: 16px;
    padding-bottom: 50px;
  }

  section.nexa-video-business .free-quote {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    clip-path: unset;
    padding-left: 25px;
    right: 0;
    top: 92%;
    width: 100%;
  }

  section.nexa-video-business .about-list > li {
    padding-right: 22px;
    padding-bottom: 25px;
  }
}

@media screen and (max-width: 991px) {
  section.nexa-video-business {
    padding-bottom: 0px;
  }
}

@media screen and (max-width: 1051px) {
  #videoShapeFrame {
    display: none;
  }
  #formBox {
    margin-top: -107px !important;
  }
}

@media screen and (max-width: 1050px) and (min-width: 767px) {
  section.nexa-video-business .about-play-img .fa {
    float: left;
    margin-right: -1%;
    margin-top: 0;
    width: 90px;
    height: 90px;
    padding-top: 10px;
    padding-left: 6px;
  }

  section.nexa-video-business .about-list > li {
    padding-right: 85px;
  }

  section.nexa-video-business .about-video-img {
    display: none;
  }

  section.nexa-video-business {
    background-image: none;
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
    margin-top: 40px;
  }

  .about-play-img > h4 {
    color: #8a8a8a;
    float: left;
    display: block;
    font-size: 23px;
    margin-top: 33px;
    margin-left: 25px;
  }

  section.nexa-video-business .video-about-detail {
    padding-top: 35px;
  }

  section.nexa-video-business .video-about-detail > h5 {
    width: 100%;
    padding-bottom: 30px;
  }

  section.nexa-video-business .video-about-detail > p {
    padding-bottom: 40px;
  }

  section.nexa-video-business .free-quote {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    clip-path: unset;
    left: 0;
    top: 91%;
  }
}

@media screen and (max-width: 1191px) and (min-width: 1051px) {
  section.nexa-video-business .free-quote {
    top: 88%;
  }

  section.nexa-video-business .about-play-img .fa {
    float: right;
    margin-right: 7%;
    margin-top: 113%;
  }

  section.nexa-video-business .about-video-img .about-img-video {
    width: 407px;
  }

  section.nexa-video-business .video-about-detail > h5 {
    padding-bottom: 30px;
    width: 60%;
  }

  section.nexa-video-business .video-about-detail {
    padding-top: 32px;
  }
}

@media screen and (max-width: 1600px) and (min-width: 1400px) {
  section.nexa-video-business {
    background-size: 37% 100%;
  }

  section.nexa-video-business .about-play-img .fa {
    margin-right: 15%;
    margin-top: 82%;
  }

  section.nexa-video-business .about-video-img .about-img-video {
    width: 595px;
  }
}

@media screen and (max-width: 1750px) and (min-width: 1650px) {
  section.nexa-video-business .about-play-img .fa {
    margin-right: 23%;
    margin-top: 85%;
  }

  section.nexa-video-business .about-video-img .about-img-video {
    width: 50%;
  }
}

@media screen and (max-width: 1900px) and (min-width: 1751px) {
  section.nexa-video-business .about-video-img .about-img-video {
    width: 665px;
  }

  section.nexa-video-business .about-play-img .fa {
    margin-right: 26%;
  }
}

@media screen and (max-width: 2000px) and (min-width: 1901px) {
  section.nexa-video-business .about-video-img .about-img-video {
    width: 720px;
  }

  section.nexa-video-business .about-play-img .fa {
    margin-right: 32%;
  }
}

@media screen and (max-width: 2350px) and (min-width: 2051px) {
  section.nexa-video-business .about-play-img .fa {
    margin-right: 45%;
  }

  section.nexa-video-business .about-video-img .about-img-video {
    width: 870px;
  }
}

@media screen and (max-width: 2050px) and (min-width: 2001px) {
  section.nexa-video-business .about-play-img .fa {
    margin-right: 34%;
  }

  section.nexa-video-business .about-video-img .about-img-video {
    width: 740px;
  }
}

@media screen and (max-width: 2750px) and (min-width: 2400px) {
  section.nexa-video-business .about-video-img .about-img-video {
    width: 1100px;
    border-radius: 0 32% 0 0;
  }

  section.nexa-video-business .about-play-img .fa {
    margin-right: 56%;
    padding-top: 44px;
  }

  section.nexa-aboutnexa-business .about-service-list > li {
    width: 100%;
  }

  .nexa-video-business .about-video-shapes img {
    height: 608px;
  }
}

@media screen and (min-width: 3300px) {
  section.nexa-video-business .about-play-img .fa {
    margin-right: 80%;
    padding-top: 44px;
  }

  section.nexa-video-business .about-video-img .about-img-video {
    width: 50%;
  }
}

@media screen and (min-width: 3800px) {
  section.nexa-video-business .about-play-img .fa {
    margin-right: 80%;
    padding-top: 44px;
  }

  section.nexa-video-business .about-video-img .about-img-video {
    width: 50%;
  }
}

@media screen and (min-width: 4400px) {
  section.nexa-video-business .about-play-img .fa {
    margin-right: 120%;
    padding-top: 44px;
  }

  section.nexa-video-business .about-video-img .about-img-video {
    width: 50%;
  }
}

@media screen and (min-width: 5000px) {
  section.nexa-video-business .about-play-img .fa {
    margin-right: 150%;
    padding-top: 44px;
  }

  section.nexa-video-business .about-video-img .about-img-video {
    width: 50%;
  }
}

#contactUsForm {
  background-image: linear-gradient(90deg,#0054a6,#5699b6);
  padding: 48px 15px 60px;
  margin-top: 35px;
  color: #ffffff;
}

#contactUsForm .formWrapper {
  max-width: 730px;
  margin: auto;
}

#contactUsForm .formHeader {
  color: #fff;
  font-size: 24px;
  font-weight: 400;
  text-align: center;
}

#contactUsForm input[type="text"],
#contactUsForm input[type="email"],
#contactUsForm input[type="tel"] {
  border-color: #f5911e;
  border-style: solid;
  border-width: 0 0 1px;
  color: #fff;
  font-size: 16px;
  padding: 30px 10px;
  background-color: rgba(255, 0, 0, 0) !important;
  width: 100%;
}

#contactUsForm ::placeholder {
  color: #fff;
}

#contactUsForm textarea,
#contactUsForm input[type="text"],
#contactUsForm input[type="email"] {
  color: #fff;
}

#contactUsForm textarea {
  border-color: #f5911e;
  border-style: solid;
  border-width: 0 0 1px;
  height: 123px;
  margin-top: 12px;
  width: 100%;
  font-size: 16px;
  color: #fff;
  background-color: rgba(255, 0, 0, 0);
}

#contactUsForm .btn-submit {
  background-color: rgba(245, 145, 30, 1);
  color: #fff;
  font-size: 16px;
  padding: 10px 20px;
  box-shadow: 0 0 10px #345063;
  border: none;
  border-radius: 20px;
  white-space: normal !important;
}
#contactUsForm .btn-success {
  background-color:#198754;
  border: 1px solid #198754 !important;
}
#contactUsForm .btn-success:hover {
  background-color: #149257 !important;
  border: 1px solid #198754 !important;
  color: #fff !important;
}
#contactUsForm .btn-submit:hover {
  background-color: #fff;
  border: 1px solid #f5911e;
  color: #f5911e;
  -webkit-transition-duration: 0.6s;
  transition-duration: 0.6s;
}

#contactUsForm .form-control {
  border-radius: 0rem;
}

#contactUsForm .form-group {
  margin-bottom: 0rem;
}
#contactUsForm .consentLink{
  color: #fff !important;
  text-decoration: underline;
}
#contactUsForm .consentLink:hover{
  color: #F5911E  !important;
  text-decoration: underline;
}
#contactUsForm .form-control:focus {
  color: rgba(255, 255, 255, 0.85);
}

#contactUsForm .invalid-feedback {
  color: #fff !important;
}

#contactUsForm .btn.disabled:hover,
#contactUsForm .btn:disabled:hover {
  cursor: not-allowed;
  border: none !important;
  color: #fff !important;
  background-color: #f5911e !important;
}

#contactUsForm .form-control.is-invalid,
#contactUsForm .was-validated .form-control:invalid {
  border-color: #dc3545 !important;
  padding-right: calc(1.5em + 0.75rem);
  /* background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23dc354…%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E")!important; */
  background-repeat: no-repeat;
  background-position: center right calc(0.375em + 0.1875rem);
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

#contactUsForm .form-check-label {
  color: #fff;
  font-weight: bold;
  text-transform: uppercase;
}

#contactUsForm .form-control.is-invalid,
#contactUsForm .was-validated .form-control:invalid {
  border-color: #dc3545 !important;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23dc3545' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23dc3545' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E") !important;
  background-repeat: no-repeat;
  background-position: center right calc(0.375em + 0.1875rem);
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

#contactUsForm .form-check-input:checked {
  background-color: #f5911e !important;
  border-color: #f5911e !important;
}

#contactUsForm .form-link:nth-of-type(2) {
  margin-right: 0.5em;
}

#contactUsForm .form-link:hover {
  text-decoration: underline !important;
}

#contactUsForm h6 {
  text-align: left;
  color: #fff;
  font-size: 12px;
}

#contactUsForm .blg-col-9 {
  text-align: left;
  font-size: 12px;
}

#contactUsForm .consent-font {
  font-size: 12px;
}

#contactUsForm .consent-font a {
  color: #f5911e;
}

#contactUsForm .blg-col-9 .form-check:nth-of-type(2) .form-check-label {
  padding-right: 0 !important;
}

#contactUsForm .blg-container.container-fluid {
  padding-top: 0 !important;
}

#contactUsForm .blg-col-0 {
  display: none !important;
}

#contactUsForm .blg-col-1,
#contactUsForm .blg-col-2,
#contactUsForm .blg-col-3,
#contactUsForm .blg-col-4 {
  width: 100% !important;
}

#contactUsForm .blg-col-5 {
  display: none !important;
}

#contactUsForm .blg-col-ty {
  background-color: #fff;
  border-radius: 1rem;
  margin-top: 24px !important;
}

#contactUsForm .blg-col-ty h4 {
  font-size: 22px;
}

#contactUsForm select {
  border-color: #f5911e;
  border-style: solid;
  border-width: 0 0 1px;
  color: #fff;
  font-size: 16px;
  padding: 18px 10px;
  background-color: rgba(255, 0, 0, 0) !important;
  width: 100%;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='white' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
}

#contactUsForm option {
  color: #164686;
}

#contactUsForm .btn-blg.btn.btn-outline-primary.btn-sm {
  background-color: #fff !important;
}
#contactUsForm .btn-blg.btn.btn-outline-primary.btn-sm,
#contactUsForm .btn-start.btn.btn-primary.btn-sm {
  font-size: 13px !important;
}
#contactUsForm .blg-col-ty {
  padding: 12px;
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
  #contactUsForm h4 {
    font-size: 21px;
  }

  #contactUsForm .btn-blg.btn.btn-outline-primary.btn-sm,
  #contactUsForm .btn-start.btn.btn-primary.btn-sm {
    font-size: 12px !important;
  }
}
@media screen and (min-width: 576px) and (max-width: 767px) {
  #contactUsForm .formWrapper {
    max-width: 498px;
  }
}

@media screen and (max-width: 767px) {
  #contactUsForm h4 {
    font-size: 17px;
  }

  #contactUsForm .btn-blg.btn.btn-outline-primary.btn-sm,
  #contactUsForm .btn-start.btn.btn-primary.btn-sm {
    font-size: clamp(9px, 1vw, 18px) !important;
  }
}

@media screen and (max-width: 575px) {
  #contactUsForm .form-check-label {
    font-size: 10.5px;
  }

  #contactUsForm .btn-submit {
    font-size: calc(7px + 1vw) !important;
  }

  #contactUsForm h4 {
    font-size: 16px;
  }

  #contactUsForm .btn-blg.btn.btn-outline-primary.btn-sm,
  #contactUsForm .btn-start.btn.btn-primary.btn-sm {
    font-size: 12px !important;
  }
}
