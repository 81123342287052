.main-footer {
    font-size: 14px;
    position: relative;
    padding: 10px 50px;
    background-color: rgba(234, 241, 245, 0.4);
    background-position: center bottom;
    background-repeat: no-repeat;
}

footer a {
    color: #222222;
}
@media screen and (max-width: 991px) {
    .main-footer {
        padding-bottom: 6rem;
    }
}
@media screen and (min-width: 992px) {
    .main-footer {
        padding-bottom: 2rem;
    }
  }